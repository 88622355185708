<template>
  <div class="login-container">
    <el-form
      :model="ruleForm2"
      :rules="rules2"
      status-icon
      ref="ruleForm2"
      label-position="left"
      label-width="0px"
      class="demo-ruleForm login-page"
    >
      <h3 class="title">校园论坛小程序</h3>
      <el-form-item prop="username">
        <el-input
          type="text"
          v-model="ruleForm2.username"
          auto-complete="off"
          placeholder="用户名"
        ></el-input>
      </el-form-item>
      <el-form-item prop="password">
        <el-input
          type="password"
          v-model="ruleForm2.password"
          auto-complete="off"
          placeholder="密码"
        ></el-input>
      </el-form-item>
      <el-form-item prop="captcha">
        <div style="display:flex;justify-content:space-between;">
          <div><el-input
              type="captcha"
              v-model="ruleForm2.captcha"
              auto-complete="off"
              placeholder="验证码"
            ></el-input></div>
          <div>
            <img
              :src="captchaPath"
              @click="getCaptcha()"
            >
          </div>
        </div>
      </el-form-item>
      <el-form-item style="width:100%;">
        <el-button
          type="danger"
          style="width:100%;"
          @click="handleSubmit"
          :loading="logining"
        >登录</el-button>
      </el-form-item>
      <!-- <el-button
        style="margin-right:80px;"
        @click="clearClick"
      >清空</el-button>
      <el-button
        style="margin-left:80px;"
        @click="toRegister"
      >注册</el-button> -->
    </el-form>
  </div>
</template>

<script>
import cryptoJs from "crypto-js";
export default {
  data() {
    return {
      from: "",
      key: "rest@126.com",
      logining: false,
      ruleForm2: {
        username: "123456",
        password: "123456",
        captcha: "",
        uuid: "",
      },
      captchaPath: "",
      rules2: {
        username: [
          {
            required: true,
            message: "please enter your account",
            trigger: "blur",
          },
        ],
        password: [
          { required: true, message: "enter your password", trigger: "blur" },
        ],
      },
      checked: false,
    };
  },
  created() {
    this.getCaptcha();
    // this.from = this.$route.query.from;
    // sessionStorage.setItem("from", this.from);
  },
  methods: {
    async handleSubmit() {
      sessionStorage.setItem("username", this.ruleForm2.username);
      let param = {
        uuid: this.ruleForm2.uuid,
        username: this.ruleForm2.username.trim(),
        password: this.encryptDes(this.ruleForm2.password.trim()),
        captcha: this.ruleForm2.captcha,
      };
      const result = await this.$http.post("/rest/user/validate", param);
      if (result.data.returnCode !== "200") {
        this.$message.error(result.data.message);
        return;
      }
      // let from = this.$route.query.from;
      // let uid = this.$route.query.uid;
      let logintoken = result.data.returnData.logintoken;
      let user = result.data.returnData.user;
      let navList = result.data.returnData.navList;
      sessionStorage.setItem("logintoken", logintoken);
      sessionStorage.setItem("user", JSON.stringify(user));
      sessionStorage.setItem("role", user.role);
      sessionStorage.setItem("navList", JSON.stringify(navList));
      sessionStorage.setItem("uid", user.uid);
      this.$router.push({ path: "/home" });
    },
    toRegister() {
      this.$router.push({ path: "/register", query: { from: this.from } });
    },
    toForget() {
      this.$router.push({ path: "/forget", query: { from: this.from } });
    },
    encryptDes(message) {
      const keyHex = cryptoJs.enc.Utf8.parse(this.key);
      const option = { mode: cryptoJs.mode.ECB, padding: cryptoJs.pad.Pkcs7 };
      const encrypted = cryptoJs.DES.encrypt(message, keyHex, option);
      return encrypted.toString();
    },
    clearClick() {
      this.ruleForm2 = {};
    },
    getUUID() {
      return "xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx".replace(/[xy]/g, (c) => {
        return (
          c === "x" ? (Math.random() * 16) | 0 : "r&0x3" | "0x8"
        ).toString(16);
      });
    },
    // 获取验证码
    getCaptcha() {
      this.ruleForm2.uuid = this.getUUID();
      this.captchaPath =
        // "http://116.62.34.65 :8084/captcha?uuid=" + this.ruleForm2.uuid;
        "http://43.136.78.6:8084/captcha?uuid=" + this.ruleForm2.uuid;
    },
  },
};
</script>

<style scoped>
.login-container {
  width: 100%;
  height: 100%;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  min-width: auto;
  z-index: 0;
  zoom: 1;
  background-color: #fff;
  background-repeat: no-repeat;
  background-size: cover;
  background-image: url(../../assets/opera/9bc1cde91856ffbfa16dba68d12f0d0c.jpeg);
  -webkit-background-size: cover;
  -o-background-size: cover;
  background-position: center 0;
}
.login-page {
  -webkit-border-radius: 5px;
  border-radius: 5px;
  margin: 180px auto;
  margin-left: 900px;
  width: 450px;
  padding: 35px 35px 15px;
  background: #fff;
  border: 1px solid #eaeaea;
  box-shadow: 0 0 25px #cac6c6;
}
label.el-checkbox.rememberme {
  margin: 0px 0px 15px;
  text-align: left;
}
</style>
